import React from 'react'
import PropTypes from 'prop-types'
import errorIcon from '../../images/error.svg'

const Tooltip = React.memo((props) => {
    const {type, message, disabled, className} = props
    const showMassageContainer = message && !disabled
    return <div className={`tooltip ${className || ''}`}>
            {props.children}
            {showMassageContainer && <span className={`tooltip__message ${type || ''}`}>
                <img className='mr-2' src={errorIcon}/>
                {message}
            </span>}
        </div>

})

Tooltip.propTypes = {
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
}

export default Tooltip
