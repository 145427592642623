import React from 'react'
import PropTypes from 'prop-types'

const InputCheckbox = React.forwardRef((props, ref) => {
    const {name, label, defaultChecked} = props
    return <div className='flex flex-row items-center my-4'>
        <input ref={ref} name={name} type='checkbox' className='input-checkbox' defaultChecked={defaultChecked}/>
        {label && <label className='ml-2' htmlFor={name}>{label}</label>}
    </div>
})

InputCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    error: PropTypes.object
}

export default InputCheckbox
