import React from "react"
import PropTypes from "prop-types"

class DownloadRow extends React.Component {
  render() {
    return(
      <div className="flex flex-row bg-dark-blue-800 p-4 items-center hover:bg-dark-blue-700">
        <div dangerouslySetInnerHTML={{ __html: this.props.icon }}/>
        <div className="flex flex-col ml-6">
            <span className="font-bold text-lg">{this.props.title}</span>
            <a className="text-sm" href={this.props.downloadPath} download>Download</a>
        </div>
      </div>
    )
  }
}

DownloadRow.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  downloadPath: PropTypes.string.isRequired
}

export default DownloadRow
