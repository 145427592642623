import React from "react"
import PropTypes from "prop-types"
import noticeClose from '../../images/notice-close.svg'

class Notice extends React.Component {

  state = {
    close: false
  }

  timer = null

  componentDidMount() {
    if (this.props.timeout) {
      this.timer = setTimeout(() => this.setState({close: true}), 3000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    if (this.state.close) return null

    return (
      <div className={`${this.props.className} relative display-linebreak`}>
        <img src={noticeClose}
             alt="close"
             className="absolute top-0 right-0 m-4 cursor-pointer"
             onClick={() => this.setState({close: true})}/>
        {this.props.text}
      </div>
    )
  }
}

Notice.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  timeout: PropTypes.bool.isRequired
}

export default Notice