import React, {useEffect, useRef} from 'react'
import checkGreenIcon from '../../../assets/images/check-green.svg'
import PropTypes from 'prop-types'

const WizardStepContainer = (props) => {
    const {stepId, header, activeStep, setActiveStep, value, onExpand, onCollapse} = props
    const contentRef = useRef()

    const isActive = stepId === activeStep

    useEffect(() => {
        if(isActive) {
            const content = contentRef.current
            content.style.display = 'flex'
            content.style.height = 'auto'
            onExpand()
        }
    }, [])

    useEffect(() => {
        contentRef.current.style.display = 'flex'
        const formHeight = contentRef.current.offsetHeight
        const toHeight = isActive ? contentRef.current.scrollHeight : 0

        if(formHeight === toHeight) {
            return
        }

        const transitionend = () => {
            if(isActive) {
                contentRef.current.style.height = 'auto'
                if(onExpand) {
                    onExpand()
                }
            } else {
                contentRef.current.style.display = 'hidden'
                if(onCollapse) {
                    onCollapse()
                }
            }
            contentRef.current.removeEventListener('transitionend', transitionend)
        }
        contentRef.current.addEventListener('transitionend', transitionend)

        requestAnimationFrame(() => {
            contentRef.current.style.height = formHeight + 'px'
            requestAnimationFrame(() => contentRef.current.style.height = toHeight + 'px')
        })

    }, [isActive])

    return (
        <div key={stepId}
             className={`wizard-step-container flex flex-col bg-dark-blue-800 rounded-lg mb-2 ${isActive ? 'active' : '' + ''}`}>
            <div className='flex flex-row flex-1 items-center justify-between cursor-pointer'
                 onClick={() => setActiveStep(stepId)}>
                <div className={`flex p-4 text-lg max-w-xl ${isActive ? 'font-semibold' : 'font-normal'}`}>
                    {header}
                </div>
                {!isActive && value &&
                <span className='flex flex-row p-4 font-semibold'>{value}<img src={checkGreenIcon} alt='check icon'
                                                                              className='ml-4'/></span>}
            </div>
            <div ref={contentRef}
                 className={`wizard-step-content hidden flex-col h-0 overflow-hidden transition-all duration-300 ease-linear`}>
                <hr className='border border-dark-blue-700'/>
                <div className='flex flex-col p-4 max-w-xl'>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

WizardStepContainer.propTypes = {
    stepId: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    activeStep: PropTypes.string.isRequired,
    setActiveStep: PropTypes.func.isRequired,
    value: PropTypes.string,
    onExpand: PropTypes.func,
    onCollapse: PropTypes.func,
}

export default WizardStepContainer
