import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../tooltip/Tooltip'

const InputText = React.forwardRef((props, ref) => {
    const {name, label, value, error, className} = props
    return <Fragment>
        {label && <label htmlFor={name}>{label}</label>}
        <Tooltip className={`mb-2 flex flex-column ${className || ''}`} message={error ? error.message : ''} type='error'>
            <input ref={ref} value={value} autoFocus={true} name={name} type='text'
                   className={`input-text flex-1 ${error ? 'has-errors' : ''}`}/>
        </Tooltip>
    </Fragment>
})

InputText.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.object
}

export default InputText
