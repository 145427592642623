import React from "react"
import PropTypes from "prop-types"

class LandingPageNavigation extends React.Component {
  state = {
    menuOpen: false
  }

  toggleMenuOpen = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }

  menuButtonClass = () => {
    return this.state.menuOpen ?
      "text-black text-light text-xl cursor-pointer lg:hidden" :
      "text-tg-yellow-700 text-light text-xl cursor-pointer lg:hidden"
  }

  menuBottonName = () => {
    return this.state.menuOpen ? "Close Menu" : "Menu"
  }

  render() {
    const lgMenuItems = <React.Fragment>
      {/*<a href="/features" className="mr-8 whitespace-no-wrap">Features</a>*/}
      <a href="/pricing" className="mr-8 whitespace-no-wrap">Pricing</a>
      <a href="/contacts/new" className="mr-8 whitespace-no-wrap">Contact Us</a>
      <a href={this.props.login_path} className="mr-8">Login</a>
      <a href={this.props.signup_path} className="btn-transparent whitespace-no-wrap">Get Started</a>
    </React.Fragment>

    const smMenuItems = <React.Fragment>
      {/*<a href="/features" className="mb-4 text-black">Features</a>*/}
      <a href="/pricing" className="mb-4 text-black whitespace-no-wrap">Pricing</a>
      <a href="/contacts/new" className="mb-4 text-black whitespace-no-wrap">Contact Us</a>
      <a href={this.props.login_path} className="mb-4 text-black whitespace-no-wrap" >Login</a>
      <a href={this.props.signup_path} className="mb-4 text-black whitespace-no-wrap">Get Started</a>
    </React.Fragment>

    return (
      <div className="flex flex-col relative w-full">
        <div className="flex items-center justify-between p-8 relative z-30">
          <a href="/">
            <img src={this.props.logo} className="object-scale-down h-20"/>
          </a>
          <div className={this.menuButtonClass()}
               onClick={() => this.toggleMenuOpen()}>
            {this.menuBottonName()}
          </div>
          <div className="hidden lg:flex flex-row items-center justify-end text-white w-1/2 text-xl">
            {lgMenuItems}
          </div>
        </div>
        {
          this.state.menuOpen &&
          <div className="absolute menu-backdrop w-full pt-32 z-20 fadein lg:hidden">
            <div className="flex flex-col items-center text-center text-black text-xl p-8">
              {smMenuItems}
            </div>
          </div>
        }
      </div>
    )
  }
}

LandingPageNavigation.propTypes = {
  user: PropTypes.object,
  authenticated: PropTypes.bool,
  session: PropTypes.object,
  signup_path: PropTypes.string,
  logo: PropTypes.string,
  login_path: PropTypes.string,
  logo_link_url: PropTypes.string
}

export default LandingPageNavigation