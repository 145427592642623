import React from 'react'
import PropTypes from 'prop-types'
import {DiagnosticNotebook} from '@idex/diagnostic-notebook'


export default class Notebook extends React.Component {
  render() {
    return <div className='container mx-auto mb-20'>
      <DiagnosticNotebook id={this.props.id}
                          data={this.preprocess()}
                          className='mx-8 rounded-lg'
                          readOnly={true}/>
    </div>
  }

  preprocess() {
    const {current_url, contents} = this.props

    if (!contents || !contents.blocks) {
      return contents
    }

    for (const block of contents.blocks) {
      if (block.type === 'image') {
        if (!block.data || !block.data.file || !block.data.file.url) {
          continue
        }
        const attachmentId = this.parseAttachmentId(block.data.file.url)
        if (attachmentId) {
          block.data.file.url = `${current_url}/a/${attachmentId}`
        }
      }
    }

    return contents
  }

  parseAttachmentId(url) {
    if (!url.startsWith('db-notebook')) {
      return null
    }
    return url.substr(url.lastIndexOf('/') + 1)
  }
}

Notebook.propTypes = {
  id: PropTypes.string.isRequired,
  current_url: PropTypes.string.isRequired,
  contents: PropTypes.object,
}
