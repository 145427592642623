import React from 'react'
import PropTypes from 'prop-types'
import Notice from './Notice'
import orgIcon from '../../images/organizations.svg'
import projectsIcon from '../../images/projects.svg'
import teamsIcon from '../../images/teams.svg'
import usersIcon from '../../images/users.svg'
import downloadIcon from '../../images/download.svg'
import profileIcon from '../../images/profile.svg'
import infoIcon from '../../images/info.svg'

class Sidebar extends React.Component {
  render() {
    const {
      organizationName, organizationPath, organizationsPath, organizationTeamsPath,
      organizationUsersPath, organizationProjectsPath,
      welcomePath, downloadsListPath, userProfilePath,
      showProjects, showTeams, showUsers
    } = this.props

    const notice = this.props.notice ? <Notice text={this.props.notice} className="notice ok my-2" timeout={true}/> : null
    const alert = this.props.alert ? <Notice text={this.props.alert} className="notice alert my-2" timeout={false}/> : null
    const error = this.props.error !== "" ? <Notice text={this.props.error} className="notice alert my-2" timeout={false}/> : null

    return (
      <div className="flex flex-col">
        {organizationName && <Container>
          <div className={`flex flex-col p-4 cursor-pointer ${window.location.pathname.startsWith(organizationPath) ? 'bg-dark-blue-700' : ''}`}
               onClick={() => Turbolinks.visit(organizationPath)}>
              <div className='text-light-gray text-md'>Organization</div>
              <div className='text-lg'>{organizationName}</div>
          </div>
          {showProjects && <NavItem title="Projects" icon={projectsIcon} path={organizationProjectsPath} />}
          {showUsers && <NavItem title='Users' icon={usersIcon} path={organizationUsersPath} />}
          {showTeams && <NavItem title='Teams' icon={teamsIcon} path={organizationTeamsPath} />}
        </Container>}

        <Container>
          {window.location.pathname.startsWith(welcomePath)
            ? <NavItem title='Get Started' icon={infoIcon} path={welcomePath} />
            : <NavItem title='Organizations' icon={orgIcon} path={organizationsPath} />}
        </Container>

        <Container>
          <NavItem title='Download App' icon={downloadIcon} path={downloadsListPath} />
        </Container>

        {userProfilePath && <Container>
          <NavItem title='My Profile' icon={profileIcon} path={userProfilePath} />
        </Container>}

        {error}
        {alert}
        {notice}
      </div>
    )
  }
}

Sidebar.propTypes = {
  organizationName: PropTypes.string,
  organizationPath: PropTypes.string,
  organizationTeamsPath: PropTypes.string,
  organizationUsersPath: PropTypes.string,
  organizationProjectsPath: PropTypes.string,
  organizationsPath: PropTypes.string,
  organizationNewPath: PropTypes.string,
  userProfilePath: PropTypes.string.isRequired,
  downloadsListPath: PropTypes.string.isRequired,
  welcomePath: PropTypes.string,
  notice: PropTypes.string,
  alert: PropTypes.string,
  showTeams: PropTypes.bool,
  showUsers: PropTypes.bool,
  showProjects: PropTypes.bool
}

const NavItem = (props) => (
  <div className={`flex p-4 cursor-pointer ${window.location.pathname === props.path ? 'bg-dark-blue-700' : ''}`}
       onClick={() => Turbolinks.visit(props.path)}>
    <div className='flex justify-center w-6 mr-4'>
      <img src={props.icon} alt={props.title} />
    </div>
    <p className='col-span-7 text-lg'>{props.title}</p>
  </div>
)

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

const Container = (props) => (
  <div className={`flex flex-col rounded-lg bg-dark-blue-800 overflow-hidden mb-2 ${props.className || ''}`}>
    {props.children}
  </div>
)

Container.propTypes = {
  className: PropTypes.string,
}

export default Sidebar
