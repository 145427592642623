import React, {useState, useRef} from 'react'
import {useForm} from 'react-hook-form'
import PropTypes from 'prop-types'
import Rails from '@rails/ujs'
import InputText from '../form/InputText'
import InputCheckbox from '../form/InputCheckbox'
import WizardStepContainer from './WizardStepContainer'
import WizardStepFooter from './WizardStepFooter'

const NOT_EMPTY_FIELD = 'Can\'t be empty'

const WIZARD_STEP_1 = 'wizard_step_1'
const WIZARD_STEP_2 = 'wizard_step_2'
const WIZARD_STEP_3 = 'wizard_step_3'


function Wizard(props) {
    const firstStep = props.org_id ? WIZARD_STEP_2 : WIZARD_STEP_1
    const [step, setStep] = useState(firstStep)
    const orgNameRef = useRef()
    const projectNameRef = useRef()
    const plNameRef = useRef()
    const productNameRef = useRef()
    const formRef = useRef()

    const {register, errors, getValues, trigger} = useForm({shouldFocusError: true});
    const formValue = getValues()

    const goToStep = (nextStep, validateFields) => {
        if (validateFields) {
            trigger(validateFields).then((isValid) => {
                isValid ? setStep(nextStep) : errors[validateFields].ref.focus()
            })
        } else {
            setStep(nextStep)
        }
    }

    const cancel = () => Turbolinks.visit(props.go_back_url)

    const handleSubmit = async () => {
        const isValid = await trigger(firstStep === WIZARD_STEP_1 ? ['org_name', 'project_name', 'product_name'] : ['project_name', 'product_name'])
        if (isValid) {
            plNameRef.current.value = projectNameRef.current.value
            formRef.current.submit()
        } else {
            const firstField = Object.keys(errors)[0]
            if (errors['product_name']) {
                productNameRef.current.focus()
            } else if (firstField === 'org_name') {
                setStep(WIZARD_STEP_1)
            } else if (firstField === 'project_name') {
                setStep(WIZARD_STEP_2)
            }
        }
    }

    const stepIndex = firstStep === WIZARD_STEP_1 ? 0 : -1

    return (
        <form ref={formRef} action={`${props.action}`} method='POST'
              className='flex flex-col p-2 font-light wizard-step-collapse-animation' autoComplete='off'>
            <input type="hidden" name="authenticity_token" value={Rails.csrfToken()}/>
            {firstStep === WIZARD_STEP_1 &&
            <WizardStepContainer stepId={WIZARD_STEP_1}
                                 value={formValue['org_name']}
                                 header={`Step ${stepIndex + 1}. Create An Organization`}
                                 activeStep={step}
                                 setActiveStep={(step) => setStep(step)}
                                 onExpand={() => orgNameRef.current.focus()}>
                <div className='bg-dark-blue-700 rounded-lg my-2 p-4'>
                    An Organization is the business or institution that develops and publishes diagnostics on IDEx. For example: Tweddle Group, Inc.
                </div>

                <InputText name='org_name'
                           label='Name Your Organization'
                           error={errors['org_name']}
                           ref={(ref) => {
                               register(ref, {required: {value: true, message: NOT_EMPTY_FIELD}})
                               orgNameRef.current = ref
                           }}/>

                <div className='mb-2'>
                    <span className='font-semibold'>Note:</span> Your organization name will be visible to anyone who has access to download your diagnostics. You must have permission to use this name.
                </div>
                <WizardStepFooter prevOnClick={cancel}
                                  prevTitle='Cancel'
                                  nextOnClick={() => goToStep(WIZARD_STEP_2, 'org_name')}
                                  isActiveStep={step === WIZARD_STEP_1}/>
            </WizardStepContainer>}
            <WizardStepContainer stepId={WIZARD_STEP_2}
                                 value={formValue['project_name']}
                                 header={`Step ${stepIndex + 2}. Create a New Project`}
                                 activeStep={step}
                                 setActiveStep={(step) => setStep(step)}
                                 onExpand={() => projectNameRef.current.focus()}>

                <div className='bg-dark-blue-700 rounded-lg my-2 p-4'>
                    A project contains all of the authored diagnostic information and data that can be published. You choose who can access the project as an author or admin.
                </div>

                <InputText name='project_name'
                           label='Project Name'
                           error={errors['project_name']}
                           ref={(ref) => {
                               register(ref, {required: {value: true, message: NOT_EMPTY_FIELD}})
                               projectNameRef.current = ref
                           }}/>
                <InputText className='hidden' name='pl_name' ref={ref => (plNameRef.current = ref)} />
                <div className='mb-2'>
                    <span className='font-semibold'>Note:</span> The project name can only be viewed by other
                    administrators and authors in your organization.
                </div>
                <WizardStepFooter prevTitle={firstStep === WIZARD_STEP_1 ? 'Go Back' : 'Cancel'}
                                  prevOnClick={firstStep === WIZARD_STEP_1 ? () => goToStep(WIZARD_STEP_1) : cancel}
                                  nextOnClick={() => goToStep(WIZARD_STEP_3, 'project_name')}
                                  isActiveStep={step === WIZARD_STEP_2}/>
            </WizardStepContainer>
            <WizardStepContainer stepId={WIZARD_STEP_3}
                                 value={formValue['product_name']}
                                 header={`Step ${stepIndex + 3}. Add a Product`}
                                 activeStep={step}
                                 setActiveStep={(step) => setStep(step)}
                                 onExpand={() => productNameRef.current.focus()}
            >

                <div className='bg-dark-blue-700 rounded-lg my-2 p-4'>
                    Technicians will have to choose a product from your product line before they can start a diagnostic. Don't worry, IDEx offers advanced configuration for product attributes, tagging, and even serial number decoding when you need it.
                </div>

                <InputText name='product_name'
                           label='Product Name'
                           error={errors['product_name']}
                           ref={(ref) => {
                               register(ref, {required: {value: true, message: NOT_EMPTY_FIELD}})
                               productNameRef.current = ref
                           }}/>
                <InputCheckbox name='seed_data' label='Create sample diagnostics'
                               defaultChecked={true}
                               ref={register()}/>

                <div>
                    <span className='font-semibold'>Note:</span> Sample diagnostics offer a good place to get started if want help understanding how
                    diagnostics are structured inside the IDEx desktop application.
                </div>
                <WizardStepFooter prevOnClick={() => goToStep(WIZARD_STEP_2)}
                                  nextTitle='Submit'
                                  nextOnClick={handleSubmit}
                                  isActiveStep={step === WIZARD_STEP_3}/>
            </WizardStepContainer>
        </form>
    )
}

Wizard.propTypes = {
    action: PropTypes.string,
    go_back_url: PropTypes.string,
    org_id: PropTypes.string,
}

export default Wizard
