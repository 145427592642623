import React from "react"
import PropTypes from "prop-types"
import TableRow from "./TableRow"

class Table extends React.Component {
  render() {

    const tableHeaders = this.props.headers.map((header, index) => {
      return (
        <th className="font-thin text-sm pl-4" key={`header-${index}`}>{header}</th>
      )
    })

    const tableRows = this.props.rows.map((row, index) => {
      return (
        <TableRow data={row.data} path={row.path} key={`table-row-${index}`} accessible={row.accessible} />
      )
    })

    const empty = tableRows.length === 0 ?
      <div className="p-8">
        Nothing has been added.
      </div>
      : null

    return (
      <div className='w-full text-center'>
        <table className="table-auto w-full text-left">
          <thead className="bg-dark-blue-700">
          <tr>
            {tableHeaders}
          </tr>
          </thead>
          <tbody>
          {tableRows}
          </tbody>
        </table>
        {empty}
      </div>
    )
  }
}

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.string).isRequired,
    accessible: PropTypes.bool.isRequired
  })).isRequired
}

export default Table