import React from "react"
import PropTypes from "prop-types"

class TableRow extends React.Component {

  selectRow = (path) => {
    if (this.props.accessible) {
      Turbolinks.visit(path)
    }
  }

  render() {
    const rowClass = `bg-dark-blue-800 ${this.props.accessible ? 'cursor-pointer hover:bg-dark-blue-700' : ''}`
    return(
      <tr className={rowClass}>
        {this.props.data.map((text, index) => {
          return (
            <td className="text-lg p-4" key={`table-data-${index}`} onClick={() => this.selectRow(this.props.path)}>
              {text}
            </td>
          )
        })}
      </tr>
    )
  }
}

TableRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.string.isRequired,
  accessible: PropTypes.bool.isRequired
}

export default TableRow