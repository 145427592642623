import React from "react"
import PropTypes from "prop-types"

class UserCard extends React.Component {

  state = {
    open: false
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {

    return (
      <div className={`user-card h-full cursor-pointer lg:cursor-auto ${this.props.background}`} onClick={() => this.toggleOpen()}>
        <div className="flex items-center">
          <div dangerouslySetInnerHTML={{__html: this.props.icon}}/>
          <div className="text-white font-bold ml-8">
            {this.props.title}
          </div>
        </div>
        <div className={`block mt-8`}>
          {this.props.texts.map((text, index) => {
            return (
              <div className="text-white mb-4">
                {text}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

UserCard.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  texts: PropTypes.arrayOf(PropTypes.string).isRequired,
  background: PropTypes.string
}

export default UserCard