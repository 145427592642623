import React from "react"
import PropTypes from "prop-types"

class Navigation extends React.Component {
  state = {
    menuOpen: false
  }

  toggleMenuOpen = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }

  menuButtonClass = () => {
    return this.state.menuOpen ?
      "text-black text-light text-xl cursor-pointer lg:hidden" :
      "text-tg-yellow-700 text-light text-xl cursor-pointer lg:hidden"
  }

  menuBottonName = () => {
    return this.state.menuOpen ? "Close Menu" : "Menu"
  }

  render() {
    const logInOutButtonLg = this.props.authenticated ?
      <a href={this.props.logout_path} className="btn-transparent">Logout</a> :
      <a href={this.props.login_path} className="btn-transparent">Login</a>

    const logInOutButtonSm = this.props.authenticated ?
      <a href={this.props.logout_path} className="mb-4 text-black">Logout</a> :
      <a href={this.props.login_path} className="mb-4 text-black">Login</a>

    return (
      <div className="flex flex-col relative w-full">
        <div className="flex items-center justify-between p-8 relative z-30">
          <a href={this.props.logo_link_url}>
            <img src={this.props.logo} className="object-scale-down h-20"/>
          </a>
          <div className={this.menuButtonClass()}
               onClick={() => this.toggleMenuOpen()}>
            {this.menuBottonName()}
          </div>
          <div className="hidden lg:flex flex-row items-center justify-end text-white w-1/2 text-xl">
            <React.Fragment>
              {logInOutButtonLg}
            </React.Fragment>
          </div>
        </div>
        {
          this.state.menuOpen &&
          <div className="absolute menu-backdrop w-full pt-16 z-20 fadein lg:hidden">
            <div className="flex flex-col items-center text-center text-black text-xl p-8">
              <React.Fragment>
                {logInOutButtonSm}
              </React.Fragment>
            </div>
          </div>
        }
      </div>
    )
  }
}

Navigation.propTypes = {
  user: PropTypes.object,
  authenticated: PropTypes.bool,
  session: PropTypes.object,
  logo: PropTypes.string,
  logout_path: PropTypes.string,
  login_path: PropTypes.string,
  logo_link_url: PropTypes.string
}

export default Navigation