import React from 'react'

const WizardStepFooter = (props) => {
    const {prevOnClick, nextOnClick, prevTitle, nextTitle, isActiveStep} = props
    return (
        <div className='flex flex-row justify-between my-2'>
            <button type='button' onClick={(e) => {
                e.preventDefault()
                prevOnClick(e)
            }} className='btn-transparent w-38'>{prevTitle || 'Go Back'}</button>
            <button type={isActiveStep ? 'submit' : 'button'} onClick={(e) => {
                e.preventDefault()
                nextOnClick(e)
            }} className='btn-yellow w-38'>{nextTitle || 'Continue'}</button>
        </div>
    )
}

export default WizardStepFooter
